import React, { useState, FunctionComponent, useEffect } from 'react';

// Libs
import classnames from 'classnames';

// Contexts
import { useViewDetected } from '@Components/Utils/ViewDetected';

// Components
import Page from '@Components/Utils/Page';
import ComponentLazy from '@Components/Utils/Lazy/Component';
import PlaceholderView from '@Components/UI/PlaceholderView';
import ComingSoon from '@Pages/gioi-thieu/Components/ComingSoon';

// Types
import { NextSeoProps } from 'next-seo';
import { GetServerSideProps, InferGetServerSidePropsType } from 'next';

// Styles
import styles from './IntroUser.module.scss';
import { getListEClinic, getMedicalSpecialities } from 'src/apis/graphql/api';
import { SupportButton } from '@Components/Layout/Components/SupportButton';

const IntroUser: FunctionComponent<InferGetServerSidePropsType<typeof getServerSideProps>> = () => {
  const { deviceType } = useViewDetected();

  const isMobile = deviceType !== 'desktop';

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dataListing, setDataListing] = useState<Array<any>>([]);
  const [medicalSpecialities, setMedicalSpecialities] = useState<any>([]);

  const wrapperClassName = classnames([styles.wrapper, { [styles.mobile]: isMobile }]);

  useEffect(() => {
    getListEClinic({})
      .then(res => {
        if (res.code === 1000) {
          setDataListing(res?.data?.eClinics ?? [])
        } else {
          setDataListing([])
        }
      })
      .catch(err => console.log('error listing', err))

    getMedicalSpecialities({})
      .then(res => {
        if (res.code === 1000) {
          setMedicalSpecialities(res?.data?.medicalSpecialities ?? [])
        } else {
          setMedicalSpecialities({})
        }
      })
      .catch(err => console.log('error getMedicalSpecialities', err))
  }, [])

  const toggleModal = () => {
    setIsOpen((currentIsOpen) => !currentIsOpen);
  };

  return (
    <Page className={wrapperClassName}>
      <ComponentLazy
        openModal={toggleModal}
        className={styles.costing}
        dynamic={() => import('@Pages/gioi-thieu/nguoi-dung/Components/Header')}
        placeholder={() => <PlaceholderView mobileHeight={744} tabletHeight={744} desktopHeight={660} />}
      />
      {dataListing.length > 0 && (
        <ComponentLazy
          openModal={toggleModal}
          className={styles.costing}
          dynamic={() => import('@Pages/gioi-thieu/nguoi-dung/Components/Choice')}
          placeholder={() => <PlaceholderView mobileHeight={744} tabletHeight={744} desktopHeight={882} />}
          data={dataListing}
          medicalSpecialities={(medicalSpecialities ?? []).map(item => {
            return {
              label: item?.name,
              value: item?.medicalSpecialtyId
            }
          })}
        />
      )}

      {/* <ComponentLazy
        openModal={toggleModal}
        className={styles.costing}
        dynamic={() => import('@Pages/gioi-thieu/nguoi-dung/Components/Preferential')}
        placeholder={() => <PlaceholderView mobileHeight={744} tabletHeight={744} desktopHeight={702} />}
      /> */}

      <ComponentLazy
        className={styles.costing}
        dynamic={() => import('@Pages/gioi-thieu/nguoi-dung/Components/Guidance')}
        placeholder={() => <PlaceholderView mobileHeight={744} tabletHeight={744} desktopHeight={946} />}
      />

      <ComponentLazy
        className={styles.costing}
        dynamic={() => import('@Pages/gioi-thieu/nguoi-dung/Components/Benefit')}
        placeholder={() => <PlaceholderView mobileHeight={744} tabletHeight={744} desktopHeight={792} />}
      />

      <ComponentLazy
        className={styles.costing}
        dynamic={() => import('@Pages/gioi-thieu/nguoi-dung/Components/Talk')}
        placeholder={() => <PlaceholderView mobileHeight={744} tabletHeight={744} desktopHeight={968} />}
      />

      <ComponentLazy
        className={styles.costing}
        dynamic={() => import('@Pages/gioi-thieu/nguoi-dung/Components/Question')}
        placeholder={() => <PlaceholderView mobileHeight={744} tabletHeight={744} desktopHeight={1056} />}
      />

      <ComponentLazy
        openModal={toggleModal}
        className={styles.costing}
        dynamic={() => import('@Pages/gioi-thieu/nguoi-dung/Components/Download')}
        placeholder={() => <PlaceholderView mobileHeight={744} tabletHeight={744} desktopHeight={888} />}
      />

      <ComingSoon isOpen={isOpen} onClose={toggleModal} />
      <SupportButton />
    </Page>
  );
};

export default IntroUser;

export const getServerSideProps: GetServerSideProps = async () => {
  const title = 'eDoctor: Giới thiệu người dùng';
  const seo: NextSeoProps = {
    title,
    openGraph: {
      title,
      type: 'website',
    },
  };

  return {
    props: {
      seo,
    },
  };
};
